<template>
  <v-container>
    <v-row class="text-center">
      <v-col>
        <v-card>
          <v-list three-line v-if="lots.length > 0">
            <template v-for="(lot, index) in lots">
              <lot-item :key="lot.title" :item="lot" />
              <v-divider :key="index" :inset="lot.inset" />
            </template>
          </v-list>
          <template v-else>
            <p class="subheading font-weight-regular">
              Nobody is selling right now.
              <br />
              <i>
                But you always can check our auction for
                <router-link :to="'/auction/grower'">Grower Lots</router-link>
                or create <a @click.stop="openBuyRequestModal">buy request</a>
              </i>
            </p>
          </template>
        </v-card>
      </v-col>
    </v-row>
    <create-buy-request ref="createBuyRequestDialog" />
  </v-container>
</template>
<script>
import LotItem from '@/components/Auction/LotItem'
import CreateBuyRequest from '@/components/Auction/Modals/CreateBuyRequest'
import {mapActions, mapGetters} from 'vuex'

export default {
  name: 'BuyTab',
  components: {
    LotItem,
    CreateBuyRequest
  },
  async mounted () {
    await this.loadLots(this.token)
  },
  computed: {
    ...mapGetters({
      token: 'user/getAccessToken',
      lots: 'auction/getLots'
    })
  },
  methods: {
    ...mapActions({
      loadLots: 'auction/loadLots'
    }),
    openBuyRequestModal () {
      this.$refs.createBuyRequestDialog.openDialog()
    }
  }
}
</script>
